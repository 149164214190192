<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/address" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Adres Ekle</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Adres Başlığı</label>
              <input
                type="text"
                class="form-control"
                name="title"
                placeholder="Adres Başlığı"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Adres</label>
              <textarea
                type="text"
                class="form-control"
                name="address"
                placeholder="Adres"
              ></textarea>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">İl</label>
              <select
                name="city"
                id="city"
                class="form-control"
                @change="cityChange"
              >
                <optgroup label="Şehir Seçiniz">
                  <option
                    v-for="(item, i) in cityList"
                    :key="i"
                    :value="item.name"
                    :data-province="JSON.stringify(item.districts)"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">İlçe</label>
              <select name="province" id="province" class="form-control">
                <optgroup label="İlçe Seçiniz">
                  <option
                    v-for="(item, i) in selectedDistricts"
                    :key="i"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div v-if="$store.state.user_type == 'EXPERT' || $store.state.user_type == 'CENTER'" class="col-sm-12 col-md-12" >
            <button class="btn btn-primary w-100 mb-2" @click="findLoc">
              Konumumu Bul
            </button>
            <GmapMap
              ref="mapRef"
              :center="center"
              :zoom="18"
              style="width: 100%; height: 400px"
            >
              <GmapMarker
                :position="center"
                :clickable="true"
                :draggable="true"
                :icon="'https://terapivitrini.com/assets/images/marker.png'"
                @dragend="change"
              />
            </GmapMap>
            <small
              >*Mevcut konumunuzu bulabilmek için konum kullanımına izin vermeyi
              deneyin</small
            ><br />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary"
        >
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Adreslerim";

    if (this.cityList.length == 0) {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
        this.selectedDistricts = value.list[0].districts;
      });
    }
  },
  data() {
    return {
      pageGet: [],
      cityList: [],
      center: { lat: 41.055048, lng: 29.009433 },
      selectedDistricts: [],
    };
  },
  methods: {
    findLoc() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    change(e) {
      this.center = e.latLng;
      return this.center;
    },
    cityChange() {
      var element = document.getElementById("city");
      var option_doj =
        element.options[element.selectedIndex].getAttribute("data-province");
      this.selectedDistricts = JSON.parse(option_doj);
    },
    save() {
      let title = document.querySelector("input[name=title]").value;
      let address = document.querySelector("textarea[name=address]").value;
      let city = document.querySelector("select[name=city]").value;
      let province = document.querySelector("select[name=province]").value;
      if (title == null || title == "") {
        this.$vToastify.warning("Başlık boş bırakılamaz", "Uyarı!");
      } else if (address == null || address == "") {
        this.$vToastify.warning("Adres boş geçilemez", "Uyarı!");
      } else if (city == null || city == "") {
        this.$vToastify.warning("Şehir boş bırakılamaz", "Uyarı!");
      } else if (province == null || province == "") {
        this.$vToastify.warning("Bölge boş bırakılamaz", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let addressSave = {
          title: title,
          address: address,
          city: city,
          province: province,
          location: JSON.stringify(this.center),
        };

        this.$store.dispatch("addressCreate", addressSave).then((value) => {
          if (value.type == "error") {
            if (value.message == "ERRxUAR") {
              this.$vToastify.warning(
                "Onay sürecindeki profil güncellenemez",
                "Uyarı!"
              );
            }
            if (value.message == "ERRxONLYONEADRS") {
              this.$vToastify.warning(
                "Sadece bir adres ekleyebilirsiniz",
                "Uyarı!"
              );
            }
          } else {
            this.$vToastify.success("Adres bilgileri kaydedildi", "Başarılı!");
            this.$router.push("/profile/address/update/" + value.created_id);
          }
        });
      }
    },
  },
  components: {},
  mounted() {},
};
</script>